var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mapIcons)?_c('div',[_c('GmapMap',{ref:"gmap",staticStyle:{"width":"100%","height":"570px"},attrs:{"zoom":_vm.trail.map.zoom,"center":{ lat: +_vm.trail.map.coordinates.lat, lng: +_vm.trail.map.coordinates.lon },"options":{
        zoomControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,

    },"map-type-id":"terrain"}},[_c('GmapPolyline',{attrs:{"path":_vm.path,"options":{'strokeWeight':_vm.content.styles.map.strokeWeight,'strokeColor':_vm.content.styles.map.strokeColor}},on:{"click":_vm.openRoute}}),_vm._l((_vm.trail.route_points),function(point){return _c('GmapMarker',{key:point.id+'gmap',attrs:{"position":{
        lat: +point.coordinates.lat,
        lng: +point.coordinates.lon,
        },"icon":{
          path: _vm.mapIcons.shapes['SQUARE_PIN'],
          fillColor: '#fafafa',
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0
        },"clickable":true},on:{"click":function($event){return _vm.openDetailPoint(point)}}})}),_vm._l((_vm.trail.route_points),function(point){return _c('GmapCustomMarker',{key:point.id+'custom',attrs:{"marker":{
        lat: +point.coordinates.lat,
        lng: +point.coordinates.lon,
        },"offsetY":-15}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(point.icon),expression:"point.icon"}],class:("map-icon map-icon-" + (point.icon.split(' ').join('-'))),staticStyle:{"font-size":"20px","color":"#000"},on:{"click":function($event){return _vm.openDetailPoint(point)}}})])})],2),_c('div',{staticStyle:{"position":"absolute","overflow":"hidden","bottom":"20px","width":"100%","padding":"0 30px"}},[_c('swiper',{ref:"mySwiper",staticStyle:{"overflow":"hidden"},style:(("height:" + (_vm.content.styles.map.img_height) + "px;")),attrs:{"direction":'horizontal',"pagination":{ clickable: true },"options":_vm.swiperOptionsListing},on:{"click-slide":_vm.openDetail}},_vm._l((_vm.trail.route_points),function(point){return _c('swiper-slide',{key:point.id,style:(("width:" + (_vm.content.styles.map.img_width) + "px;"))},[_c('img',{style:(("width:" + (_vm.content.styles.map.img_width) + "px;height:" + (_vm.content.styles.map.img_height) + "px;border-radius:5px;")),attrs:{"src":point.main_img ? point.main_img : require('@/assets/none.png')}})])}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }