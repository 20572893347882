<template>
  <div>
      <template v-if="page === 'list'">
        <typeTrailsListPreview @openPoints="openPointsList" :content="content"/>
      </template>
      <template v-if="page === 'points'">
          <typeTrailPointsListPreview @openDetail="openTrailPointDetail" :content="content"/>
      </template>
      <template v-if="page === 'map'">
          <typeTrailsMap :content="content"/>
      </template>
      <template v-if="page === 'detail'">
        <typeTrailPointDetailPreview :content="content"/>
      </template>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import typeTrailsListPreview from "./typeTrails/typeTrailsListPreview.vue";
import typeTrailsMap from "./typeTrails/typeTrailsMap.vue";
import typeTrailPointsListPreview from "./typeTrails/typeTrailPointsListPreview.vue";
import typeTrailPointDetailPreview from "./typeTrails/typeTrailPointDetailPreview.vue";
export default {
  name: "typeTrailsPreview",
  props:['item','content','selectedMenuID'],
  components: {
    typeTrailsListPreview,
    typeTrailsMap,
    typeTrailPointsListPreview,
    typeTrailPointDetailPreview
  },
  data() {
    return {
        localPage:"list",
        points:[],
    };
  },
  methods: {
    ...mapActions("trails", ["getTrailList"]),
    ...mapMutations("trails",['setTrail','setPage','setTrailPoint']),
    openTrailPointDetail(point){
      console.log(point);
      this.setTrailPoint(point);
      this.setPage('detail')
    },
    openPointsList(id){
        const candidate = this.trailList.find(trail => trail.id === id);
        if(candidate){
            this.setTrail(candidate);
            this.setPage('points');
        }
    }
  },
  computed: {
    ...mapState("trails", ["trailList","page"]),
  },
  async mounted() {
  //     let id;
  //     if(this.item.type === 13){
  //         id = +(this.selectedMenuID+""+this.item.id+'000');
  //     }else{
  //         id = this.item.id;
  //     }
  //   const payload = {
  //     hotel_id: +this.$route.params.id,
  //     menu_id: id,
  //   };
  // await this.getTrailList(payload);
  },
};
</script>

<style lang="scss" scoped></style>
в