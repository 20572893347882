<template>
    <div v-if="mapIcons">
        <GmapMap
        ref="gmap"
        :zoom="trail.map.zoom"
        :center="{ lat: +trail.map.coordinates.lat, lng: +trail.map.coordinates.lon }"
        :options="{
            zoomControl: false,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: false,
            fullscreenControl: false,

        }"
        map-type-id="terrain"
        style="width: 100%; height: 570px"
        >
        <GmapPolyline  :path="path" @click="openRoute" :options="{'strokeWeight':content.styles.map.strokeWeight,'strokeColor':content.styles.map.strokeColor}"/>

        <GmapMarker
            v-for="point in trail.route_points" :key="point.id+'gmap'"
            :position="{
            lat: +point.coordinates.lat,
            lng: +point.coordinates.lon,
            }"
            @click="openDetailPoint(point)"
            :icon=" {
              path: mapIcons.shapes['SQUARE_PIN'],
              fillColor: '#fafafa',
              fillOpacity: 1,
              strokeColor: '',
              strokeWeight: 0
            }"
            :clickable="true"
        >
        </GMapMarker>
        <GmapCustomMarker
            v-for="point in trail.route_points" :key="point.id+'custom'"
            :marker="{
            lat: +point.coordinates.lat,
            lng: +point.coordinates.lon,
            }"
            :offsetY="-15"
            >
          <span v-show="point.icon"
            @click="openDetailPoint(point)" :class="`map-icon map-icon-${point.icon.split(' ').join('-')}`" style="font-size:20px;color:#000;"></span>
        </GmapCustomMarker>
        </GmapMap> 
        <div style="position:absolute;overflow:hidden;bottom:20px;width:100%;padding: 0 30px;">
            
        <swiper
            ref="mySwiper"
            :direction="'horizontal'"
            :pagination="{ clickable: true }"
            :options="swiperOptionsListing"
            style="overflow: hidden;"
            :style="`height:${content.styles.map.img_height}px;`"
            @click-slide="openDetail"
        >
            <swiper-slide
            v-for="point in trail.route_points"
            :key="point.id"
            :style="`width:${content.styles.map.img_width}px;`"
            >
            <img
                :src="point.main_img ? point.main_img : require('@/assets/none.png')"
                :style="`width:${content.styles.map.img_width}px;height:${content.styles.map.img_height}px;border-radius:5px;`"
            />
            </swiper-slide>
        </swiper>
        </div>

        </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import GmapCustomMarker from 'vue2-gmap-custom-marker';
import mapIcons from "@/assets/map-icons/js/map-icons.js"
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);

    export default {
        name:"typeTrailsMap",
        props:['content'],
        components:{
            GmapCustomMarker,
            Swiper, SwiperSlide
        },
        methods:{
            ...mapMutations('trails',['setPage','setTrailPoint']),
            openRoute(){
                console.log('rote')
            },
            openDetailPoint(point){
                this.setTrailPoint(point);
                this.setPage('detail');
            },
            openDetail(index){
                if(index >= 0){
                const point = this.trail.route_points[index]
                this.setTrailPoint(point);
                this.setPage('detail');
                }
            }
        },
        computed:{
            ...mapState('trails',['trail']),
            ...mapState(['landing'])
        },
        beforeCreate(){

        },
        data(){
            return{
                swiperOptionsListing: {
                    slidesPerView: "auto",
                    spaceBetween: 15,
                    grabCursor: true,
                    pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                    },
                },
                mapIcons:null,
                startLocation:{
                    lat:45.22,
                    lng:13.59
                },
                endLocation:{
                    lat:45.81,
                    lng:15.98
                },
                path:[]
            }
        },
        mounted(){
            this.mapIcons = mapIcons;
            if(this.trail.enable_custom_path){
                this.path = this.trail.route;
            }else{
                if(this.trail.route_points.length >= 2){
                    setTimeout(() => {
                        let directionService = new window.google.maps.DirectionsService();

                        const waypoints = [];
                        const start = {
                            lat:+this.trail.route_points[0].coordinates.lat,
                            lng:+this.trail.route_points[0].coordinates.lon
                        }
                        const end = {
                            lat:+this.trail.route_points[this.trail.route_points.length-1].coordinates.lat,
                            lng:+this.trail.route_points[this.trail.route_points.length-1].coordinates.lon
                        }
                        for(let i = 1;i<this.trail.route_points.length-1;i++){
                            const LatLng = {
                                lat:+this.trail.route_points[i].coordinates.lat,
                                lng:+this.trail.route_points[i].coordinates.lon,
                            }
                            const waypoint = {
                                location:LatLng,
                                stopover:true
                            }
                            waypoints.push(waypoint);
                        }
                        directionService.route({
                            origin:start,
                            destination:end,
                            travelMode:this.trail.map.travel_mode,
                            waypoints:waypoints
                        }, (response,status) => {
                            var legs = response.routes[0].legs;
                            for (let i=0;i<legs.length;i++) {
                                var steps = legs[i].steps;
                                for (let j=0;j<steps.length;j++) {
                                    var nextSegment = steps[j].path;
                                    for (let k=0;k<nextSegment.length;k++) {
                                        const obj =  {
                                            lat:nextSegment[k].lat(),
                                            lng:nextSegment[k].lng()
                                        }
                                        this.path.push(obj);
                                    }
                                }
                            }
                        })
                    },3000)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>