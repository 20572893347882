var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"min-height":"600px"}},[_c('div',{staticClass:"listing-full",staticStyle:{"position":"relative","display":"flex","flex-direction":"column","justify-content":"space-between","overflow":"hidden"},style:(_vm.liningStyles(_vm.trailPoint) +
      "margin-top:0;height: 100%;margin-right:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";min-height:600px;margin-left:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";")},[_c('div',[(_vm.trailPoint.images.length)?_c('swiper',{ref:"mySwiper",staticStyle:{"overflow":"hidden"},style:(("width: " + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";height:" + (_vm.content.styles.points.list_img_height) + "px;")),attrs:{"direction":'horizontal',"pagination":{ clickable: true },"options":_vm.swiperOptionsListing},on:{"slideChange":_vm.changeSwiperIndex}},_vm._l((_vm.trailPoint.images),function(img){return _c('swiper-slide',{key:img,staticStyle:{"width":"330px"}},[_c('img',{style:(("width: " + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";height:" + (_vm.content.styles.points.list_img_height) + "px;")),attrs:{"src":img,"alt":""}})])}),1):_vm._e(),(_vm.trailPoint.images.length === 0)?_c('swiper',{ref:"mySwiper",staticStyle:{"overflow":"hidden"},style:(("width: " + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";height:" + (_vm.content.styles.points.list_img_height) + "px;")),attrs:{"direction":'horizontal',"pagination":{ clickable: true },"options":_vm.swiperOptionsListing},on:{"slideChange":_vm.changeSwiperIndex}},[_c('swiper-slide',{staticStyle:{"width":"330px"}},[_c('img',{style:(("width: " + (_vm.landing.branding.lining_margin ? '330px' : '345px') + ";height:" + (_vm.content.styles.points.list_img_height) + "px;")),attrs:{"src":_vm.trailPoint.main_img
                ? _vm.trailPoint.main_img
                : require('@/assets/none.png'),"alt":""}})])],1):_vm._e(),_c('div',{staticStyle:{"height":"auto","min-height":"100px","width":"100%","padding":"10px"},domProps:{"innerHTML":_vm._s(_vm.htmlData(_vm.trailPoint.description[_vm.landing.current_lang]))}})],1),(_vm.prevPoint || _vm.nextPoint)?_c('div',[_c('div',{staticClass:"label",staticStyle:{"width":"100%","text-align":"left","padding":"5px 15px"}},[_vm._v(" Points ")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","padding":"15px","width":"100%"}},[_c('div',[(_vm.prevPoint)?_c('img',{staticStyle:{"cursor":"pointer","width":"100px","height":"100px","border-radius":"5px"},attrs:{"src":_vm.prevPoint.main_img
                ? _vm.prevPoint.main_img
                : require('@/assets/none.png'),"alt":""},on:{"click":function($event){return _vm.openDetail(_vm.prevPoint)}}}):_vm._e()]),_c('div',[(_vm.nextPoint)?_c('img',{staticStyle:{"cursor":"pointer","width":"100px","height":"100px","border-radius":"5px"},attrs:{"src":_vm.nextPoint.main_img
                ? _vm.nextPoint.main_img
                : require('@/assets/none.png'),"alt":""},on:{"click":function($event){return _vm.openDetail(_vm.nextPoint)}}}):_vm._e()])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }