<template>
  <div>
    <div
      :style="
        liningStyles +
        `margin-top:${
          landing.advanced.global.active_header_inside ||
          landing.advanced.global.active_logo_inside
            ? '20px'
            : `${+landing.branding.topMenu.styles.size * 2.5}px`
        };margin-left:${
          landing.branding.lining_margin ? '10px' : '0'
        };margin-right:${landing.branding.lining_margin ? '10px' : '0'};min-height:300px;`
      "
      style="padding: 15px;min-height:300px;"
    >
      <div v-html="content.styles.trail.top_text[landing.current_lang]"></div>
      <div>
        <div
          style="margin-bottom: 15px"
          v-if="content.styles.trail.vector === 'horizontal'"
        >
          <swiper
            ref="mySwiper"
            :options="swiperOptions"
            @click-slide="openPoints"
            :style="`overflow:hidden;margin:0 auto;`"
          >
            <swiper-slide
              :id="`trail_${trailIndex}`"
              class="listing"
              v-for="(trail, trailIndex) in trailList.filter((x) => x.active)"
              :key="trail.id"
              :style="`background:${
                content.styles.trail.displayType === 'inside' && trail.main_img
                  ? 'url(' + trail.main_img + ')'
                  : '#fff'
              };background-color:#fff;background-size:100% 100%;border-radius:${
                styles.borderRadius
              }px;min-height:${content.styles.trail.height}px;width:${
                content.styles.trail.width
              }px;`"
            >
              <div>
                <div
                  :class="`listing__item__img__${trailIndex}`"
                  v-if="
                    trail.main_img &&
                    content.styles.trail.displayType !== 'inside'
                  "
                  style="float: left; margin-right: 5px; width: 100%"
                  :style="`overflow:hidden;max-width:${
                    content.styles.trail.displayType === 'horizontal'
                      ? content.styles.trail.img_width + 'px'
                      : '100%'
                  };border-radius:${styles.borderRadius}px;height:${
                    content.styles.trail.img_height
                  }px;`"
                >
                  <img
                    :src="trail.main_img"
                    alt=""
                    :style="`height:${
                      content.styles.trail.img_height
                    }px;width:${
                      content.styles.trail.displayType === 'horizontal'
                        ? content.styles.trail.img_width + 'px'
                        : '100%'
                    };border-radius:${styles.borderRadius}px;`"
                    style="width: 100%"
                  />
                </div>
                <div
                  class="listing__descr"
                  style="height: 100%"
                  :style="`display:${
                    content.styles.trail.displayType === 'inside'
                      ? 'flex'
                      : 'block'
                  };justify-content:center;text-align:${
                    content.styles.trail.displayType !== 'horizontal'
                      ? 'center'
                      : 'left'
                  };padding-left: ${
                    content.styles.trail.displayType !== 'horizontal'
                      ? '0'
                      : '10px'
                  };margin-left: ${
                    content.styles.trail.displayType !== 'horizontal'
                      ? '0'
                      : '10px'
                  };`"
                >
                  <div
                    :style="`border-radius:${styles.borderRadius}px;padding:${
                      content.styles.trail.displayType === 'inside'
                        ? '15px'
                        : '0'
                    };`"
                  >
                    <p
                      class="listing__name"
                      :style="`color:${content.styles.trail.nameColor};font-weight:${content.styles.trail.nameFontWeight};font-size:${content.styles.trail.nameFontSize}px;font-style:${content.styles.trail.nameFontStyle};font-family:${content.styles.trail.nameFontFamily};`"
                    >
                      {{ trail.name[landing.current_lang] }}
                    </p>
                    <p
                      class="listing__text"
                      style="margin-top: 10px"
                      v-html="trail.small_description[landing.current_lang]"
                    ></p>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>
        <div v-if="content.styles.trail.vector === 'vertical'">
          <div>
            <div
              :id="`listing_${listingIndex}`"
              class="listing listing__item"
              style="
                margin-bottom: 30px;
                display: block;
                padding: 5px;
              "
              v-for="(listing, listingIndex) in trailList.filter(
                (x) => x.active
              )"
              :key="listingIndex"
              :style="
                displayType(listing) +
                `border-radius:5px;min-height:${content.styles.trail.height}px;`
              "
              @click.prevent="openPointsByID(listing.id)"
            >
              <div
                :class="`listing__item__img__${listingIndex}`"
                v-if="
                  listing.main_img &&
                  content.styles.trail.displayType !== 'inside'
                "
                style="float: left; margin-right: 5px"
                :style="`overflow:hidden;width:${
                  content.styles.trail.displayType === 'horizontal'
                    ? content.styles.trail.img_width + 'px'
                    : '100%'
                };border-radius:5px;height:${
                  content.styles.trail.img_height
                }px;`"
              >
                <img
                  :src="listing.main_img"
                  alt=""
                  :style="`height:${content.styles.trail.img_height}px;width:${
                    content.styles.trail.displayType === 'horizontal'
                      ? content.styles.trail.img_width + 'px'
                      : '100%'
                  };border-radius:5px;`"
                  style="width: 100%"
                />
              </div>
              <div
                class="listing__descr"
                style="height: 100%"
                :style="`display:${
                  content.styles.trail.displayType === 'inside'
                    ? 'flex'
                    : 'block'
                };justify-content:center;align-items:center;text-align:${
                  content.styles.trail.displayType !== 'horizontal'
                    ? 'center'
                    : 'left'
                };padding-left: ${
                  content.styles.trail.displayType !== 'horizontal'
                    ? '0'
                    : '10px'
                };margin-left: ${
                  content.styles.trail.displayType !== 'horizontal'
                    ? '0'
                    : '10px'
                };`"
              >
                <div
                  :style="`border-radius:5px;padding:${
                    content.styles.trail.displayType === 'inside' ? '15px' : '0'
                  };`"
                >
                  <p
                    class="listing__name"
                    :style="`color:${content.styles.trail.nameColor};font-weight:${content.styles.trail.nameFontWeight};font-size:${content.styles.trail.nameFontSize}px;font-style:${content.styles.trail.nameFontStyle};font-family:${content.styles.trail.nameFontFamily};`"
                  >
                    {{ listing.name[landing.current_lang] }}
                  </p>
                  <p
                    class="listing__text"
                    style="margin-top: 10px"
                    v-html="listing.small_description[landing.current_lang]"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);
import { mapState } from "vuex";
export default {
  name: "typeTrailsListPreview",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        spaceBetween: 15,
      },
      styles: {
        content_position: "center flex-start",
        vector: "horizontal",
        block_width: "200",
        block_height: "400",
        img_height: "100",
        img_width: "100",
        borderRadius: "5",
        displayType: "default",
        nameColor: "#000",
        nameFontWeight: 500,
        nameFontSize: "15",
        nameFontStyle: "normal",
        nameFontFamily: "Inter",
      },
    };
  },
  methods: {
    displayType(listing) {
      if (this.content.styles.trail.displayType === "horizontal") {
        return "flex-direction:row;align-items:normal;";
      } else if (this.content.styles.trail.displayType === "inside") {
        let bg = `url(${listing.main_img}) no-repeat center / 100%`;
        return `background:${bg};
            background-size:100%;
            overflow:hidden;
            text-align:center;
            height:200px;
            border-radius:10px;
            background-position: center center;
            background-repeat:no-repeat;`;
      } else {
        return "flex-direction:column;";
      }
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
    openPoints(index) {
      if (index >= 0) {
        this.$emit("openPoints", this.trailList[index].id);
      }
    },
    openPointsByID(id) {
      this.$emit("openPoints", id);
    },
  },
  props: ["content"],
  computed: {
    ...mapState("trails", ["trailList"]),
    ...mapState(["landing"]),
    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.listing {
  background-color: #fff;
}
</style>
