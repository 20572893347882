<template>
  <div style="min-height: 600px">
    <div
      class="listing-full"
      :style="
        liningStyles(trailPoint) +
        `margin-top:0;height: 100%;margin-right:${
          landing.branding.lining_margin ? '10px' : '0'
        };min-height:600px;margin-left:${
          landing.branding.lining_margin ? '10px' : '0'
        };`
      "
      style="
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
      "
    >
      <div>
        <swiper
          ref="mySwiper"
          :direction="'horizontal'"
          :pagination="{ clickable: true }"
          :options="swiperOptionsListing"
          style="overflow: hidden"
          :style="`width: ${
            landing.branding.lining_margin ? '330px' : '345px'
          };height:${content.styles.points.list_img_height}px;`"
          v-if="trailPoint.images.length"
          @slideChange="changeSwiperIndex"
        >
          <swiper-slide
            v-for="img in trailPoint.images"
            :key="img"
            style="width: 330px"
          >
            <img
              :src="img"
              alt=""
              :style="`width: ${
                landing.branding.lining_margin ? '330px' : '345px'
              };height:${content.styles.points.list_img_height}px;`"
            />
          </swiper-slide>
        </swiper>
        <swiper
          ref="mySwiper"
          :direction="'horizontal'"
          :pagination="{ clickable: true }"
          :options="swiperOptionsListing"
          style="overflow: hidden"
          :style="`width: ${
            landing.branding.lining_margin ? '330px' : '345px'
          };height:${content.styles.points.list_img_height}px;`"
          v-if="trailPoint.images.length === 0"
          @slideChange="changeSwiperIndex"
        >
          <swiper-slide style="width: 330px">
            <img
              :src="
                trailPoint.main_img
                  ? trailPoint.main_img
                  : require('@/assets/none.png')
              "
              alt=""
              :style="`width: ${
                landing.branding.lining_margin ? '330px' : '345px'
              };height:${content.styles.points.list_img_height}px;`"
            />
          </swiper-slide>
        </swiper>
        <div
          style="height: auto; min-height: 100px; width: 100%; padding: 10px"
          v-html="htmlData(trailPoint.description[landing.current_lang])"
        ></div>
      </div>
      <div v-if="prevPoint || nextPoint">
        <div
          class="label"
          style="width: 100%; text-align: left; padding: 5px 15px"
        >
          Points
        </div>
        <div
          style="
            display: flex;
            justify-content: space-between;
            padding: 15px;
            width: 100%;
          "
        >
          <div>
            <img
              v-if="prevPoint"
              :src="
                prevPoint.main_img
                  ? prevPoint.main_img
                  : require('@/assets/none.png')
              "
              alt=""
              style="
                cursor: pointer;
                width: 100px;
                height: 100px;
                border-radius: 5px;
              "
              @click="openDetail(prevPoint)"
            />
          </div>
          <div>
            <img
              v-if="nextPoint"
              :src="
                nextPoint.main_img
                  ? nextPoint.main_img
                  : require('@/assets/none.png')
              "
              alt=""
              style="
                cursor: pointer;
                width: 100px;
                height: 100px;
                border-radius: 5px;
              "
              @click="openDetail(nextPoint)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);
import { mapMutations, mapState } from "vuex";
export default {
  name: "typeTrailPointDetailPreview",
  computed: {
    ...mapState("trails", ["trailPoint", "trail"]),
    ...mapState(["landing"]),
    prevPoint() {
      const candidate = this.trail.route_points.find(
        (point) => point.id === this.trailPoint.id
      );
      if (candidate) {
        const candidateIndex = this.trail.route_points.indexOf(candidate);
        if (candidateIndex > 0) {
          return this.trail.route_points[candidateIndex - 1];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    nextPoint() {
      const candidate = this.trail.route_points.find(
        (point) => point.id === this.trailPoint.id
      );
      if (candidate) {
        const candidateIndex = this.trail.route_points.indexOf(candidate);
        if (candidateIndex < this.trail.route_points.length - 1) {
          return this.trail.route_points[candidateIndex + 1];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      activeIndex: 0,
      swiperOptionsListing: {
        grabCursor: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  methods: {
    ...mapMutations("trails", ["setTrailPoint"]),
    openDetail(point) {
      this.setTrailPoint(point);
    },
    changeSwiperIndex() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
    liningStyles(listing_item) {
      let styles;
      if (this.landing.branding.bg_lining_img) {
        styles = `background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        styles = `background-color:${this.landing.branding.bg_lining_color};`;
      }
      if (listing_item) {
        if (listing_item.images.length) {
          styles += `border-bottom-left-radius:${this.landing.branding.lining_radius}px;border-bottom-right-radius:${this.landing.branding.lining_radius}px;`;
        } else {
          styles += `border-radius:${this.landing.branding.lining_radius}px;`;
        }
      }
      return styles;
    },
  },
  props: ["content"],
};
</script>

<style lang="scss" scoped></style>
