import { render, staticRenderFns } from "./typeTrailPointsListPreview.vue?vue&type=template&id=f227222a&scoped=true&"
import script from "./typeTrailPointsListPreview.vue?vue&type=script&lang=js&"
export * from "./typeTrailPointsListPreview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f227222a",
  null
  
)

export default component.exports