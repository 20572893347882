import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:(_vm.liningStyles +
      "margin-left:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";margin-right:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";margin-top:" + _vm.marginTop + ";min-height:300px;")},[(_vm.trail.images.length)?_c('swiper',{ref:"mySwiper",staticStyle:{"overflow":"hidden","margin-bottom":"15px"},style:(("width: " + (_vm.landing.branding.lining_margin ? '325px' : '345px') + ";height:" + (_vm.content.styles.trail.img_detail_height) + "px;")),attrs:{"direction":'horizontal',"pagination":{ clickable: true },"options":_vm.swiperOptionsListing},on:{"slideChange":_vm.changeSwiperIndex}},_vm._l((_vm.trail.images),function(img){return _c('swiper-slide',{key:img,staticStyle:{"width":"330px"}},[_c('img',{style:(("width: " + (_vm.landing.branding.lining_margin ? '325px' : '345px') + ";height:" + (_vm.content.styles.trail.img_detail_height) + "px;")),attrs:{"src":img,"alt":""}})])}),1):_vm._e(),_c('div',{staticStyle:{"padding":"15px"}},[_c('div',{staticStyle:{"margin-bottom":"15px"},domProps:{"innerHTML":_vm._s(_vm.checkDescription(_vm.trail.description[_vm.landing.current_lang]))}})]),_vm._l((_vm.trail.route_points.filter(
        function (point) { return point.active; }
      )),function(point,pointIndex){return _c(VTimeline,{key:point.id,class:{
        'first--v-timeline': pointIndex === 0,
        'last--v-timeline':
          _vm.trail.route_points.filter(function (point) { return point.active; }).length ===
          pointIndex + 1,
        'disable--v--timeline':
          _vm.trail.route_points.filter(function (point) { return point.active; }).length === 1,
      },attrs:{"clipped":"","dense":"","align-top":"","fill-dot":""}},[_c(VTimelineItem,{attrs:{"color":"#fff","large":pointIndex!== 0,"left":""},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_vm._v(" "+_vm._s(pointIndex!== 0 ? _vm.checkLink(point.link) : '')+" ")]},proxy:true}],null,true)},[_c(VCard,{staticClass:"elevation-5",staticStyle:{"margin-right":"15px","height":"auto"},on:{"click":function($event){return _vm.$emit('openDetail', point)}}},[_c(VCardText,{staticStyle:{"padding":"0","height":"auto"}},[(point.main_img)?_c('div',{staticStyle:{"width":"100%"}},[_c('img',{staticStyle:{"width":"100%","border-top-left-radius":"5px","border-top-right-radius":"5px"},style:(("height:" + (_vm.content.styles.points.listing_item_img_height) + "px;")),attrs:{"src":point.main_img,"alt":""}})]):_vm._e(),_c('div',{staticStyle:{"padding":"0 15px","font-weight":"bold"}},[_vm._v(" "+_vm._s(point.name[_vm.landing.current_lang])+" ")]),_c('div',{staticStyle:{"padding":"0 15px 10px 15px"},domProps:{"innerHTML":_vm._s(point.small_description[_vm.landing.current_lang])}})])],1)],1)],1)})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }