var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"padding":"15px","min-height":"300px"},style:(_vm.liningStyles +
      "margin-top:" + (_vm.landing.advanced.global.active_header_inside ||
        _vm.landing.advanced.global.active_logo_inside
          ? '20px'
          : ((+_vm.landing.branding.topMenu.styles.size * 2.5) + "px")) + ";margin-left:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";margin-right:" + (_vm.landing.branding.lining_margin ? '10px' : '0') + ";min-height:300px;")},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.content.styles.trail.top_text[_vm.landing.current_lang])}}),_c('div',[(_vm.content.styles.trail.vector === 'horizontal')?_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c('swiper',{ref:"mySwiper",style:("overflow:hidden;margin:0 auto;"),attrs:{"options":_vm.swiperOptions},on:{"click-slide":_vm.openPoints}},_vm._l((_vm.trailList.filter(function (x) { return x.active; })),function(trail,trailIndex){return _c('swiper-slide',{key:trail.id,staticClass:"listing",style:(("background:" + (_vm.content.styles.trail.displayType === 'inside' && trail.main_img
                ? 'url(' + trail.main_img + ')'
                : '#fff') + ";background-color:#fff;background-size:100% 100%;border-radius:" + (_vm.styles.borderRadius) + "px;min-height:" + (_vm.content.styles.trail.height) + "px;width:" + (_vm.content.styles.trail.width) + "px;")),attrs:{"id":("trail_" + trailIndex)}},[_c('div',[(
                  trail.main_img &&
                  _vm.content.styles.trail.displayType !== 'inside'
                )?_c('div',{class:("listing__item__img__" + trailIndex),staticStyle:{"float":"left","margin-right":"5px","width":"100%"},style:(("overflow:hidden;max-width:" + (_vm.content.styles.trail.displayType === 'horizontal'
                    ? _vm.content.styles.trail.img_width + 'px'
                    : '100%') + ";border-radius:" + (_vm.styles.borderRadius) + "px;height:" + (_vm.content.styles.trail.img_height) + "px;"))},[_c('img',{staticStyle:{"width":"100%"},style:(("height:" + (_vm.content.styles.trail.img_height) + "px;width:" + (_vm.content.styles.trail.displayType === 'horizontal'
                      ? _vm.content.styles.trail.img_width + 'px'
                      : '100%') + ";border-radius:" + (_vm.styles.borderRadius) + "px;")),attrs:{"src":trail.main_img,"alt":""}})]):_vm._e(),_c('div',{staticClass:"listing__descr",staticStyle:{"height":"100%"},style:(("display:" + (_vm.content.styles.trail.displayType === 'inside'
                    ? 'flex'
                    : 'block') + ";justify-content:center;text-align:" + (_vm.content.styles.trail.displayType !== 'horizontal'
                    ? 'center'
                    : 'left') + ";padding-left: " + (_vm.content.styles.trail.displayType !== 'horizontal'
                    ? '0'
                    : '10px') + ";margin-left: " + (_vm.content.styles.trail.displayType !== 'horizontal'
                    ? '0'
                    : '10px') + ";"))},[_c('div',{style:(("border-radius:" + (_vm.styles.borderRadius) + "px;padding:" + (_vm.content.styles.trail.displayType === 'inside'
                      ? '15px'
                      : '0') + ";"))},[_c('p',{staticClass:"listing__name",style:(("color:" + (_vm.content.styles.trail.nameColor) + ";font-weight:" + (_vm.content.styles.trail.nameFontWeight) + ";font-size:" + (_vm.content.styles.trail.nameFontSize) + "px;font-style:" + (_vm.content.styles.trail.nameFontStyle) + ";font-family:" + (_vm.content.styles.trail.nameFontFamily) + ";"))},[_vm._v(" "+_vm._s(trail.name[_vm.landing.current_lang])+" ")]),_c('p',{staticClass:"listing__text",staticStyle:{"margin-top":"10px"},domProps:{"innerHTML":_vm._s(trail.small_description[_vm.landing.current_lang])}})])])])])}),1)],1):_vm._e(),(_vm.content.styles.trail.vector === 'vertical')?_c('div',[_c('div',_vm._l((_vm.trailList.filter(
              function (x) { return x.active; }
            )),function(listing,listingIndex){return _c('div',{key:listingIndex,staticClass:"listing listing__item",staticStyle:{"margin-bottom":"30px","display":"block","padding":"5px"},style:(_vm.displayType(listing) +
              "border-radius:5px;min-height:" + (_vm.content.styles.trail.height) + "px;"),attrs:{"id":("listing_" + listingIndex)},on:{"click":function($event){$event.preventDefault();return _vm.openPointsByID(listing.id)}}},[(
                listing.main_img &&
                _vm.content.styles.trail.displayType !== 'inside'
              )?_c('div',{class:("listing__item__img__" + listingIndex),staticStyle:{"float":"left","margin-right":"5px"},style:(("overflow:hidden;width:" + (_vm.content.styles.trail.displayType === 'horizontal'
                  ? _vm.content.styles.trail.img_width + 'px'
                  : '100%') + ";border-radius:5px;height:" + (_vm.content.styles.trail.img_height) + "px;"))},[_c('img',{staticStyle:{"width":"100%"},style:(("height:" + (_vm.content.styles.trail.img_height) + "px;width:" + (_vm.content.styles.trail.displayType === 'horizontal'
                    ? _vm.content.styles.trail.img_width + 'px'
                    : '100%') + ";border-radius:5px;")),attrs:{"src":listing.main_img,"alt":""}})]):_vm._e(),_c('div',{staticClass:"listing__descr",staticStyle:{"height":"100%"},style:(("display:" + (_vm.content.styles.trail.displayType === 'inside'
                  ? 'flex'
                  : 'block') + ";justify-content:center;align-items:center;text-align:" + (_vm.content.styles.trail.displayType !== 'horizontal'
                  ? 'center'
                  : 'left') + ";padding-left: " + (_vm.content.styles.trail.displayType !== 'horizontal'
                  ? '0'
                  : '10px') + ";margin-left: " + (_vm.content.styles.trail.displayType !== 'horizontal'
                  ? '0'
                  : '10px') + ";"))},[_c('div',{style:(("border-radius:5px;padding:" + (_vm.content.styles.trail.displayType === 'inside' ? '15px' : '0') + ";"))},[_c('p',{staticClass:"listing__name",style:(("color:" + (_vm.content.styles.trail.nameColor) + ";font-weight:" + (_vm.content.styles.trail.nameFontWeight) + ";font-size:" + (_vm.content.styles.trail.nameFontSize) + "px;font-style:" + (_vm.content.styles.trail.nameFontStyle) + ";font-family:" + (_vm.content.styles.trail.nameFontFamily) + ";"))},[_vm._v(" "+_vm._s(listing.name[_vm.landing.current_lang])+" ")]),_c('p',{staticClass:"listing__text",staticStyle:{"margin-top":"10px"},domProps:{"innerHTML":_vm._s(listing.small_description[_vm.landing.current_lang])}})])])])}),0)]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }