<template>
  <div>
    <div
      :style="
        liningStyles +
        `margin-left:${
          landing.branding.lining_margin ? '10px' : '0'
        };margin-right:${landing.branding.lining_margin ? '10px' : '0'};margin-top:${marginTop};min-height:300px;`
      "
    >
    <swiper
      v-if="trail.images.length"
      ref="mySwiper"
      :direction="'horizontal'"
      :pagination="{ clickable: true }"
      :options="swiperOptionsListing"
      style="overflow: hidden;margin-bottom:15px;"
      :style="`width: ${
        landing.branding.lining_margin ? '325px' : '345px'
      };height:${content.styles.trail.img_detail_height}px;`"
      @slideChange="changeSwiperIndex"
    >
      <swiper-slide v-for="img in trail.images" :key="img" style="width: 330px">
        <img
          :src="img"
          alt=""
          :style="`width: ${
            landing.branding.lining_margin ? '325px' : '345px'
          };height:${content.styles.trail.img_detail_height}px;`"
        />
      </swiper-slide>
    </swiper>
    <div
      style="padding: 15px">

    <!-- <div style="margin-bottom:15px;">{{trail.name[landing.current_lang]}}</div> -->
    <div v-html="checkDescription(trail.description[landing.current_lang])" style="margin-bottom:15px;"></div>
    <!-- <div style="display:flex;justify-content:center;"> -->

      <!-- <div style="flex:1;display:flex;flex-direction:column;justify-content:center;align-items:center;">
        <div>Distance</div>
        <div>{{trail.distance}}</div>
      </div>

      <div style="flex:1;display:flex;flex-direction:column;justify-content:center;align-items:center;">
        <div>Time</div>
        <div>{{trail.time}}</div>
      </div>
      
      <div style="flex:1;display:flex;flex-direction:column;justify-content:center;align-items:center;">
        <div>Difficult</div>
        <div>{{trail.difficult}}</div>
      </div> -->
    <!-- </div> -->
    </div>
      <v-timeline
        clipped
        dense
        align-top
        fill-dot
        v-for="(point, pointIndex) in trail.route_points.filter(
          (point) => point.active
        )"
        :key="point.id"
        :class="{
          'first--v-timeline': pointIndex === 0,
          'last--v-timeline':
            trail.route_points.filter((point) => point.active).length ===
            pointIndex + 1,
          'disable--v--timeline':
            trail.route_points.filter((point) => point.active).length === 1,
        }"
      >
        <v-timeline-item color="#fff" :large="pointIndex!== 0" left>
          <template v-slot:icon>
          {{pointIndex!== 0 ? checkLink(point.link) : ''}}
          </template>
          <v-card
            class="elevation-5"
            style="margin-right: 15px;height: auto;"
            @click="$emit('openDetail', point)"
          >
            <v-card-text style="padding: 0;height: auto;">
              <div
                style="width: 100%"
                v-if="point.main_img"
              >
                <img
                  :src="point.main_img"
                  alt=""
                  style="width: 100%;border-top-left-radius: 5px;border-top-right-radius: 5px;"
                  :style="`height:${content.styles.points.listing_item_img_height}px;`"
                />
              </div>
              <!-- :style="`color:${content.styles.name_color};font-size:${content.styles.name_font_size}px;font-weight:${content.styles.name_font_weight};font-family:${content.styles.name_font_family};font-style:${content.styles.name_font_style};`" -->
              <div style="padding: 0 15px;font-weight: bold;">
                {{ point.name[landing.current_lang] }}
              </div>
              <div
                style="padding: 0 15px 10px 15px;"
                v-html="point.small_description[landing.current_lang]"
              ></div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </div>
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import "swiper/modules/pagination/pagination.min.css";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
SwiperCore.use([Pagination]);
import { mapState } from "vuex";
export default {
  name: "typeTrailPointsListPreview",
  data(){
    return{
      activeIndex: 0,
      swiperOptionsListing: {
        grabCursor: true,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    }
  },
  computed: {
    ...mapState("trails", ["trail"]),
    ...mapState(["landing"]),

    liningStyles() {
      if (this.landing.branding.bg_lining_img) {
        return `border-radius:${this.landing.branding.lining_radius}px;background-image:url(${this.landing.branding.bg_lining_img});background-size:cover;background-repeat:no-repeat;`;
      } else {
        return `border-radius:${this.landing.branding.lining_radius}px;background-color:${this.landing.branding.bg_lining_color};`;
      }
    },
    marginTop(){
      if(this.trail.images.length){
        return '0'
      }
      if(this.landing.advanced.global.active_header_inside ||
          this.landing.advanced.global.active_logo_inside){
        return '20px'
      }
      return '60px'
    }
  },
  components:{
    Swiper, SwiperSlide
  },
  methods: {
      checkLink(distance){
          if(!distance){
              return '0 m'
          }
        if(+distance>1000){
            return (+distance/1000).toFixed(1)+" km"
        }else{
            return distance+" m"
        }
      },
    checkDescription(text){
      if(text){
        let newText = text.split('').join('');
        newText = newText.replaceAll(":name:", this.trail.name[this.landing.current_lang])
        newText = newText.replaceAll(":difficult:", this.trail.difficult)
        newText = newText.replaceAll(":time:", this.trail.time)
        newText = newText.replaceAll(":distance:", this.trail.distance)
        return newText;
      }else{
        return text;
      }
    },
    changeSwiperIndex() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
    },
    htmlData(data, type) {
      if (data) {
        const weatherInd = data.split(" ").indexOf(":weather:");
        let result = data.split(" ");
        if (weatherInd && this.landing.advanced.global.show_weather) {
          const newArr = result;
          newArr[weatherInd] =
            this.landing.advanced.global.weather.week.daily[0].temp.day.toFixed(
              0
            ) + " ℃";
          result = newArr;
        }
        const timeInd = data.split(" ").indexOf(":time:");
        if (timeInd) {
          const newArr = result;
          newArr[timeInd] = this.datenow;
          result = newArr;
        }
        const dateInd = data.split(" ").indexOf(":date:");
        if (dateInd) {
          const newArr = result;
          newArr[dateInd] = this.dateToday;
          result = newArr;
        }
        const user_name = data.split(" ").indexOf(":name:");
        if (
          user_name &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_name] = "Agent";
          result = newArr;
        }
        const user_surname = data.split(" ").indexOf(":surname:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_surname] = "Smith";
          result = newArr;
        }
        const user_birthday = data.split(" ").indexOf(":birthday:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_birthday] = "24.03.1999";
          result = newArr;
        }
        const user_room = data.split(" ").indexOf(":room:");
        if (
          user_surname &&
          this.landing.advanced.global.auth &&
          this.landing.advanced.auth.name &&
          type === "title"
        ) {
          const newArr = result;
          newArr[user_room] = "707";
          result = newArr;
        }

        const user_auth = data.split(" ").indexOf(":auth:");
        if (user_auth && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_auth] = `<a href="#">Login</a>`;
          result = newArr;
        }
        const user_logout = data.split(" ").indexOf(":logout:");
        if (user_logout && this.landing.advanced.global.auth && !type) {
          const newArr = result;
          newArr[user_logout] = `<a href="#">Logout</a>`;
          result = newArr;
        }
        return result.join(" ");
      }
    },
  },
  props:['content']
};
</script>

<style lang="scss" scoped></style>
